export const data = [
       [
        { img: './images/Gallery/airport/1.jpg', title: 'Airport' },
        { img: './images/Gallery/airport/2.jpg' },
        { img: './images/Gallery/airport/3.jpg' },
        { img: './images/Gallery/airport/4.jpg' },
        { img: './images/Gallery/airport/5.jpg' },
        { img: './images/Gallery/airport/6.jpg' },
        { img: './images/Gallery/airport/7.jpg' },
        { img: './images/Gallery/airport/8.jpg' },
        { img: './images/Gallery/airport/9.jpg' },
        { img: './images/Gallery/airport/10.jpg' },
        { img: './images/Gallery/airport/11.jpg' },
        { img: './images/Gallery/airport/12.jpg' },
        { img: './images/Gallery/airport/13.jpg' },
        { img: './images/Gallery/airport/14.jpg' },
        { img: './images/Gallery/airport/15.jpg' },
        { img: './images/Gallery/airport/16.jpg' },
        { img: './images/Gallery/airport/17.jpg' },
        { img: './images/Gallery/airport/18.JPG' },
        { img: './images/Gallery/airport/19.JPG' },
        { img: './images/Gallery/airport/20.JPG' },
        { img: './images/Gallery/airport/21.JPG' },
        { img: './images/Gallery/airport/22.JPG' },
       
    ],
      [
        { img: './images/Gallery/Metro/1.jpg', title: 'Metro' },
        { img: './images/Gallery/Metro/2.jpg' },
        { img: './images/Gallery/Metro/3.jpg' },
        { img: './images/Gallery/Metro/4.jpg' },
        { img: './images/Gallery/Metro/5.jpg' },
        { img: './images/Gallery/Metro/6.jpg' },
        { img: './images/Gallery/Metro/7.jpg' },
        { img: './images/Gallery/Metro/8.jpg' },
        { img: './images/Gallery/Metro/9.JPG' },
        { img: './images/Gallery/Metro/10.jpg' },
        { img: './images/Gallery/Metro/11.jpg' },
        { img: './images/Gallery/Metro/12.jpg' },
        { img: './images/Gallery/Metro/13.jpg' },
        { img: './images/Gallery/Metro/14.jpg' },
        { img: './images/Gallery/Metro/15.JPG' },
        { img: './images/Gallery/Metro/16.jpg' },
        { img: './images/Gallery/Metro/17.jpg' },
        { img: './images/Gallery/Metro/18.jpg' },
        { img: './images/Gallery/Metro/19.jpg' },
        { img: './images/Gallery/Metro/20.jpg' },
        { img: './images/Gallery/Metro/21.JPG' },
        { img: './images/Gallery/Metro/22.JPG' },
        { img: './images/Gallery/Metro/23.JPG' },
        { img: './images/Gallery/Metro/24.jpg' },
        { img: './images/Gallery/Metro/25.jpg' },
        { img: './images/Gallery/Metro/26.jpg' },
        { img: './images/Gallery/Metro/27.jpg' },
    ],
    [
        { img: './images/Gallery/AWIC/1.jpg', title: 'AWIC' },
        { img: './images/Gallery/AWIC/2.jpg' },
        { img: './images/Gallery/AWIC/3.jpg' },
        { img: './images/Gallery/AWIC/4.jpg' },
        { img: './images/Gallery/AWIC/5.jpg' },
        { img: './images/Gallery/AWIC/6.jpg' },
        { img: './images/Gallery/AWIC/7.jpg' },
        { img: './images/Gallery/AWIC/8.jpg' },
    ],
    [
        { img: './images/Gallery/IndiaBulls/1.jpg', title: 'India Bulls' },
        { img: './images/Gallery/IndiaBulls/2.jpg' },
        { img: './images/Gallery/IndiaBulls/3.jpg' },
        { img: './images/Gallery/IndiaBulls/4.jpg' },
        { img: './images/Gallery/IndiaBulls/5.jpg' },
        { img: './images/Gallery/IndiaBulls/6.jpg' },
    ],
    [
        { img: './images/Gallery/Retrofitting/1.jpg', title: 'Repair and Rehabilitation' },
        { img: './images/Gallery/Retrofitting/2.jpg' },
        { img: './images/Gallery/Retrofitting/3.jpg' },
        { img: './images/Gallery/Retrofitting/4.jpg' },
        { img: './images/Gallery/Retrofitting/5.jpg' },
    ],
    [
        { img: './images/Gallery/TataHousing/1.jpeg', title: 'Tata Housing' },
        { img: './images/Gallery/TataHousing/2.jpeg' },
        { img: './images/Gallery/TataHousing/3.jpg' },
        { img: './images/Gallery/TataHousing/4.jpeg' },
        { img: './images/Gallery/TataHousing/5.jpeg' },
        { img: './images/Gallery/TataHousing/6.jpg' },
    ],
    [
        { img: './images/Gallery/vatika/1.jpg', title: 'Vatika' },
        { img: './images/Gallery/vatika/2.jpg' },
        { img: './images/Gallery/vatika/3.jpg' },
        { img: './images/Gallery/vatika/4.jpg' },
        { img: './images/Gallery/vatika/5.jpg' },
        { img: './images/Gallery/vatika/6.jpg' },
        { img: './images/Gallery/vatika/7.jpg' },
        { img: './images/Gallery/vatika/8.jpg' },
    ],
    [
        { img: './images/Gallery/EpoxyFlooring/1.jpg', title: 'Epoxy Flooring' },
        { img: './images/Gallery/EpoxyFlooring/2.jpg' },
        { img: './images/Gallery/EpoxyFlooring/3.jpg' },
        { img: './images/Gallery/EpoxyFlooring/4.jpg' },
        { img: './images/Gallery/EpoxyFlooring/5.JPG' },
        { img: './images/Gallery/EpoxyFlooring/6.JPG' },
    ],
    [
        { img: './images/Gallery/FormulaOne/1.jpg', title: 'Formula One' },
        { img: './images/Gallery/FormulaOne/2.jpg' },
        { img: './images/Gallery/FormulaOne/3.jpg' },
        { img: './images/Gallery/FormulaOne/4.jpg' },
        { img: './images/Gallery/FormulaOne/5.JPG' },
    ],
    [
        { img: './images/Gallery/Infra/1.jpg', title: 'Infra' },
        { img: './images/Gallery/Infra/2.JPG' },
        { img: './images/Gallery/Infra/3.JPG' },
    ],
  
]